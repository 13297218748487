
import Vue from "vue"
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import {
    boldItalicPattern,
    boldPattern,
    italicPattern,
    linkPattern
} from "@/TextFormating";
import { DeepLinksTranslations } from "@/Translations";
import { Message } from "@/sinnoAppApi/Message";
import DOMPurify from 'dompurify';

@Component
export default class MessageBubble extends Vue {
    @Prop({required: true})
    public readonly message!: Message;

    public get cssClasses(): { sent: boolean; recieved: boolean; } {
        return {
            sent: this.message.sender == "company",
            recieved: this.message.sender == "user"
        }
    }

    public get timeInfo(): string {
        return this.message.contactTime.toLocaleTimeString("de-De") 
    }

    public get formattedText(): string {
        return DOMPurify.sanitize(
            this.formatLink(this.formatItalic(this.formatBold(this.formatBoldItalic(this.message.content)))),
            {ALLOWED_TAGS: ['b','i','a','u'], ALLOWED_ATTR: ['href', 'target']}
        );
    }

    private formatBoldItalic(message: string): string {
        return message.replace(boldItalicPattern, function(match, group1) {
            return '<b><i>' + group1 + '</i></b>';
        });
    }

    private formatBold(message: string): string {
        return message.replace(boldPattern, function(match, text) {
            return '<b>' + text + '</b>';
        });
    }

    private formatItalic(message: string): string {
        return message.replace(italicPattern, function(match, text) {
            return '<i>' + text + '</i>';
        });
    }

    private formatLink(message: string): string {
        // Standard Links
        message = message.replace(linkPattern, function(match, text, link) {
            return `<a href="${link ?? match}" target="_blank">${text ?? match}</a>`;
        });

        // SiNNO Links
        message = this.sinnoDeeplinks(message);

        return message;
    }

    private sinnoDeeplinks(message: string): string {
        const deepLinks = [
            'modules/orderStatus',             // Auftragsstatus
            'modules/reminders',               // Erinnerungen
            'modules/passes/selection',       // Auswahl Pässe
            'modules/passes/glasses',         // Brillenpässe
            'modules/passes/lenses',          // Linsenpässe
            'modules/passes/hearingaid',      // Hörgerätepässe
            'modules/appointment',             // Terminvereinbarung
        ];

        deepLinks.forEach((deepLink) => {
            const regex = new RegExp("[\\w\\d]*://[\\w\\-*\\d]*/" + deepLink, 'g');

            message = message.replace(regex, function(){
                return '<u>' + DeepLinksTranslations[deepLink].de + '</u>'; 
            });
        });

        return message;
    }
}
