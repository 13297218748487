
import { MessageService } from "@/MessageService";
import { Customer } from "@/sinnoAppApi/Customer";
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome"
import { library } from "@fortawesome/fontawesome-svg-core"
import { faCheckDouble } from "@fortawesome/free-solid-svg-icons"

library.add(faCheckDouble)

@Component({
    components: {
        FontAwesomeIcon: FontAwesomeIcon
    }
})
export default class NewMessage extends Vue {
    @Prop({required: true})
    public readonly service!: MessageService;

    @Prop({required: true})
    public readonly customer!: Customer;

    public async setMessagesAsRead(): Promise<void> {
        this.submitting= true;
        this.service.setMessagesAsRead(this.customer)
            .finally(() => {
                this.submitting = false
                this.$emit('setExistUnreadMessages', false)
            })
    }

    public submitting = false;
}

