
import Vue from "vue"
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";

import { MessageService } from "@/MessageService";
import { Customer } from "@/sinnoAppApi/Customer";
import { Message } from "@/sinnoAppApi/Message";
import MessageBubble from "@/components/chat/MessageBubble.vue";
import SetMessagesAsRead from "@/components/chat/SetMessagesAsRead.vue";
import NewMessage from "@/components/chat/NewMessage.vue";
import { Branch } from "@/sinnoAppApi/Branch";
import Card from "@/components/Card.vue";
import Loader from "@/components/Loader.vue";
import { SinnoAppApi } from "@/sinnoAppApi/SinnoAppApi";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome"
import { library } from "@fortawesome/fontawesome-svg-core"
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons"

library.add(faInfoCircle)

@Component({
    components: {
        MessageBubble: MessageBubble,
        NewMessage: NewMessage,
        SetMessagesAsRead: SetMessagesAsRead,
        Card: Card,
        Loader: Loader,
        FontAwesomeIcon: FontAwesomeIcon
    }
})
export default class Chat extends Vue {
    @Prop({required: true})
    public readonly customer!: Customer;

    @Prop({required: true})
    public readonly branch!: Branch;

    @Prop({required: true})
    public readonly service!: MessageService;

    public existUnreadMessages = false;
    public messages: Message[] = [];
    public infotext = "";
    public reloadMessages = false;

    private mounted() {
        this.pollingNewMessages();
    }

    public runPollingAgain(): void
    {
        this.messages = [];
        this.pollingNewMessages();
    }

    private async pollingNewMessages(): Promise<void> {
        this.loading = true;
        this.reloadMessages = false;

        let loadedFirst = false;

        try {
            for await (const newMessages of this.service.newestMessages(this.customer, true)) {
                this.checkUnreadMessages(newMessages)
                

                const epsilon = 10;
                const atBottom =
                    this.messagesScroller.scrollTop + epsilon >=
                    this.maxScroll() - epsilon

                // Temporäre Nachricht entfernen
                let messages = this.messages
                if (messages.length) {
                    if (messages[messages.length - 1].id == "-1") {
                        messages.pop()
                    }
                }

                this.messages = this.setShowDateInMessages([...this.messages, ...newMessages])
                this.loading = false;

                if((atBottom || !loadedFirst) && this.messages.length > 0) {
                    await this.$nextTick()
                    loadedFirst = true;
                    this.scrollToBottom();
                }
            }
        } catch (error_){
            const error = error_ as Error;
            
            if (error.message == "End Polling") {
                this.reloadMessages = true;
            } else {
                this.infotext = this.$t("messages.no_new_messages_loading").toString();
            }
        }
    }

    public setExistUnreadMessages(value: boolean) : void {
        this.existUnreadMessages = value
    }

    //public async addTempMessage(message: Message): Promise<void> {
    public async addTempMessage(): Promise<void> {
        this.setExistUnreadMessages(false)
        
        // ToDo: Scrollen nach ganz unten geht nicht!
        /*this.messages = [...this.messages, message]
        this.scrollToBottom()*/
    }

    private get messagesScroller() {
        return (this.$refs.messages as HTMLDivElement)
    }

    private maxScroll() {
        return this.messagesScroller.scrollHeight - this.messagesScroller.clientHeight;
    }

    private scrollToBottom() {
        this.messagesScroller.scrollTop = this.maxScroll();
    }

    public async loadOldMessages(): Promise<void> {
        if(this.messages[0] != undefined) {
            if(this.oldMessageIterator == undefined) {
                this.oldMessageIterator = this.service.messagesBefore(
                    this.messages[0],
                    this.customer
                )
            }
            const {done, value: oldMessages} = await this.oldMessageIterator.next();
            if(!done) {
                this.messages = this.setShowDateInMessages([...oldMessages, ...this.messages]);
                this.checkUnreadMessages(this.messages)
            }
            this.hasOldMessages = oldMessages.length == SinnoAppApi.maxPageSize;
        }
    }

    private checkUnreadMessages(messsages: Message[]): void
    {
        if (this.existUnreadMessages === false) {
            for (let message of messsages) {
                if (message.sender == "company") continue // check only messages from the user!

                // State can be "send" or "received" to detect unread message 
                if (message.status == "send" || message.status == "received") {
                    this.existUnreadMessages = true
                    break
                }
            }
        }
    }

    private setShowDateInMessages(messages: Message[]): Message[] {
        let oldDate = '';

        for (let message of messages) {
            let messageDate = message.contactTime.toLocaleDateString(navigator.language);

            if (messageDate != oldDate) {
                message.showDate = true
                oldDate = messageDate
            }
            else message.showDate = false
        }
        
        return messages
    }

    private oldMessageIterator?: AsyncIterator<Message[], Message[]>;
    public hasOldMessages = true;
    public loading = false;

    public async onScroll(): Promise<void> {
        if(this.messagesScroller.scrollTop == 0 && this.hasOldMessages && !this.loading) {
            this.loading = true;
            await this.loadOldMessages();
            const style = getComputedStyle((this.messagesScroller.parentElement as HTMLElement))
            const scroll = parseFloat(style.fontSize.replace(/\D/g,"") ) * 6;
            this.messagesScroller.scrollTop = scroll;
                this.loading = false;
        }
    }
}
