
import Vue from "vue";
import Component from "vue-class-component";
import { Prop, Watch } from "vue-property-decorator";

@Component
export default class TextArea extends Vue {
    @Prop({required: true})
    public name!: string;

    @Prop({required: true})
    public value!: string;

    @Prop({default: false})
    public disabled!: boolean;

    private mounted() {
        this.setHeight()
    }

    public onInput(): void {
        this.$emit("input", this.element.value)
    }

    @Watch("__value")
    private setHeight() {
        this.element.style.height = "auto";
        if(this.value != "") {
            this.element.style.height = this.element.scrollHeight + "px";
        } else {
            this.element.style.height = this.element.clientHeight + "px";
        }
    }

    public get __value(): string {
        return this.value;
    }

    private get element() {
        return this.$refs.elem as HTMLTextAreaElement;
    }

    private rows = 1;
}

