
import { MessageService } from "@/MessageService";
import { Branch } from "@/sinnoAppApi/Branch";
import { Customer } from "@/sinnoAppApi/Customer";
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import TextArea from "../input/TextArea.vue";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome"
import { library } from "@fortawesome/fontawesome-svg-core"
import { faArrowRight, faLevelDownAlt, faHourglass } from "@fortawesome/free-solid-svg-icons"

library.add(faArrowRight)
library.add(faLevelDownAlt)
library.add(faHourglass)

@Component({
    components: {
        TextArea: TextArea,
        FontAwesomeIcon: FontAwesomeIcon
    }
})
export default class NewMessage extends Vue {
    @Prop({required: true})
    public readonly service!: MessageService;

    @Prop({required: true})
    public readonly customer!: Customer;

    @Prop({required: true})
    public readonly branch!: Branch;

    public doEnter(): void {
        this.message += "\r\n";
        document.getElementById('messageContentTextInput')?.focus();
    }
    
    public get submitIcon(): string {
        return this.submitting ? 'hourglass' : 'arrow-right'
    }

    public async sendMessage(): Promise<void> {
        if (this.message.trim().length < 1) {
            document.getElementById('messageContentTextInput')?.focus();
            return;
        }

        const message = this.message

        this.submitting= true;
        this.service.sendMessage(message, this.branch, this.customer)
            .finally(() => {
                const m = {
                    id: "-1",
                    branchId: "1",
                    contactTime: new Date,
                    title: "",
                    content: message,
                    status: "send",
                    sender: "company",
                    showDate: false
                }

                this.service.setMessagesAsRead(this.customer)
                this.$emit('addTempMessage', m)
                this.submitting = false
            })
        this.message = "";
    }

    public message = "";
    public submitting = false;
}

